export const Apps = [
  {
    id: "focus",
    dispName: "Focus",
    link: "https://focus.fsm.northwestern.edu/",
  },
  {
    id: "emerg",
    dispName: "eMerg",
    link: "https://emerg.fsm.northwestern.edu/",
  },
  {
    id: "oasis",
    dispName: "Oasis",
    link: "https://clerkship.northwestern.edu/index.html",
  },
  {
    id: "canvas",
    dispName: "Canvas",
    link: "https://canvas.northwestern.edu/",
  },
  {
    id: "panopto",
    dispName: "Panopto",
    link:
      "https://feinberg-northwestern.hosted.panopto.com/Panopto/Pages/Home.aspx",
  },
  {
    id: "galter-library",
    dispName: "Galter Library",
    link: "https://galter.northwestern.edu/",
  },
];
