const local = {
  rbac_enabled: true,
  auth_api_root: "",
  auth_api_key: "",
  portal_api_root: "https://pt-dev.api.fsm.northwestern.edu",
  portal_api_key: "0oyhzcbe2y6tttdlAtFO33wojzCpldKQ67J855vM",
};

const dev = {
  rbac_enabled: true,
  auth_api_root: "https://auth-dev.api.fsm.northwestern.edu",
  auth_api_key: "sVefAxct7JlOXsAaGHuY30gHFHzPeQGaKLPjvrlc",
  portal_api_root: "https://pt-dev.api.fsm.northwestern.edu",
  portal_api_key: "0oyhzcbe2y6tttdlAtFO33wojzCpldKQ67J855vM",
};

const uat = {
  rbac_enabled: true,
  auth_api_root: "https://auth-uat.api.fsm.northwestern.edu",
  auth_api_key: "hsQQhVxIwO7ayVgzD72sW4i35e8g1FBLRsMbUYxg",
  portal_api_root: "https://pt-uat.api.fsm.northwestern.edu",
  portal_api_key: "iFvThq5d7i2i4kWPkIRZl4j5px31o2uR8uewEDwD",
};

const prod = {
  rbac_enabled: true,
  auth_api_root: "https://auth.api.fsm.northwestern.edu",
  auth_api_key: "5RVNeVP1Kc1lIxhHVJpTw2giziTowySd76dlg15N",
  portal_api_root: "https://pt.api.fsm.northwestern.edu",
  portal_api_key: "jZorAg9YqN576ua7znr4M19V8F0Jyxmqvs1dj2t2",
};

let config = null;

switch (process.env.REACT_APP_ENV.toLowerCase()) {
  case "local":
    config = local;
    break;
  case "dev":
    config = dev;
    break;
  case "uat":
    config = uat;
    break;
  case "prod":
    config = prod;
    break;
  default:
    alert("Invalid config!");
    break;
}

let configExport = {
  ...config,
};

export default configExport;
