import React from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Can from "./auth/Can";
import { useUserContext } from "./context/UserContext";

const useStyles = makeStyles((theme) => ({
  appLinks: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: theme.spacing(2),
    backgroundColor: "#fcfcfc",
  },
}));

export default function App() {
  const classes = useStyles();
  let { user } = useUserContext();
  let { roles } = user || [];

  return (
    <Box my={4}>
      <Typography variant="h4" component="h1" gutterBottom>
        Developer Links
      </Typography>
      <Box my={4} className={classes.appLinks}>
        {roles ? (
          <Can
            roles={user.roles}
            perform="developer-pages:visit"
            yes={() => "Authorized! Placeholder for developer links."}
            no={() => "Unauthorized! Only developers can view developer links."}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
}
