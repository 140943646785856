export const Utils = [
  {
    id: "admin",
    dispName: "Admin",
    link: "https://medweb02.northwestern.edu/adm",
  },
  {
    id: "clerklog-admin",
    dispName: "Clerklog Administration",
    link:
      "https://medweb02.northwestern.edu/ome/ClerklogsII/PatientEncountersAD/selectclerkshipadmin.cfm",
  },
  {
    id: "cpes",
    dispName: "Clinical Performance Evaluation System",
    link: "https://emerg.fsm.northwestern.edu/eMerg/SummativeGrades",
  },
  {
    id: "development",
    dispName: "Development",
    link: "https://medweb02.northwestern.edu/dev",
  },
  {
    id: "direct-obs",
    dispName: "Direct Observation Form",
    link:
      "https://emerg.fsm.northwestern.edu/Forms/SharedSupplementalFeedback/Edit/",
  },
  {
    id: "eib",
    dispName: "Exam Item Bank",
    link: "https://medweb02.northwestern.edu/examitembank",
  },
  {
    id: "eqb",
    dispName: "Exam Question Bank",
    link: "https://medweb02.northwestern.edu/eib",
  },
  {
    id: "mspe",
    dispName: "MSPE",
    link: "https://medweb02.northwestern.edu/msp",
  },
  {
    id: "osce",
    dispName: "OSCE Scores",
    link: "https://learningspace.fsm.northwestern.edu/",
  },
  {
    id: "piazza",
    dispName: "Piazza",
    link: "https://piazza.com/",
  },
  {
    id: "portfolio-system",
    dispName: "Portfolio System",
    link: "https://medweb02.northwestern.edu/index.cfm",
  },
  {
    id: "reports",
    dispName: "Reports",
    link: "https://medweb02.northwestern.edu/rpt",
  },
  {
    id: "ssw",
    dispName: "Secured Student Website",
    link: "http://secure.feinberg.northwestern.edu/studentinfo/index.html",
  },
  {
    id: "ses",
    dispName: "Student Evaluation System",
    link: "https://medweb02.northwestern.edu/index.cfm",
  },
  {
    id: "spt",
    dispName: "Student Performance Tracking",
    link: "https://medweb02.northwestern.edu/spt",
  },
  {
    id: "srd",
    dispName: "Student Record Database",
    link: "https://medweb02.northwestern.edu/srd",
  },
  {
    id: "tools",
    dispName: "Tools",
    link: "https://medweb02.northwestern.edu/tls",
  },
];
