import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import Config from "../config/Config";

export const UserContext = createContext();
export const UserContextConsumer = UserContext.Consumer;
export const useUserContext = () => useContext(UserContext);

const UserContextProvider = (props) => {
  const [user, setUser] = useState();
  const [webSSOIVEnc, setWebSSOIVEnc] = useState();
  const [webSSOTokenEnc, setWebSSOTokenEnc] = useState();
  const {
    auth_api_root,
    auth_api_key,
    portal_api_root,
    portal_api_key,
  } = Config;
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "local") {
      setWebSSOIVEnc("xxx");
      setWebSSOTokenEnc("xxx");
    } else {
      axios
        .get(document.location.origin)
        .then((response) => {
          setWebSSOIVEnc(response.headers["websso-iv"]);
          setWebSSOTokenEnc(response.headers["websso-token"]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "local") {
      setTimeout(() => {
        const getUser = async () => {
          let _roles = await axios.get(
            `${portal_api_root}/portal/netid/mlh025`,
            {
              headers: {
                "x-api-key": portal_api_key,
              },
            }
          );

          setUser({
            netid: "localuser",
            username: "Local User",
            title: "Admin",
            roles: _roles.data.data[0].ROLES,
          });
        };

        getUser();
      }, 2000);
    } else {
      if (webSSOTokenEnc && webSSOIVEnc) {
        axios
          .get(auth_api_root + "/user-info", {
            headers: {
              "x-api-key": auth_api_key,
              "websso-token": webSSOTokenEnc,
              "websso-iv": webSSOIVEnc,
            },
          })
          .then((response) => {
            const getUser = async (_user) => {
              let _roles = await axios.get(
                `${portal_api_root}/portal/netid/${_user.netid}`,
                {
                  headers: {
                    "x-api-key": portal_api_key,
                  },
                }
              );

              setUser({
                ..._user,
                roles: _roles.data.data[0].ROLES,
              });
            };

            getUser(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [webSSOTokenEnc, webSSOIVEnc]);

  const handleLogout = () => {
    setLoggingOut(true);
    if (process.env.REACT_APP_ENV === "local") {
      setTimeout(() => {
        alert("Logout is disabled in local environment.");
        setLoggingOut(false);
      }, 3000);
    } else {
      if (webSSOTokenEnc && webSSOIVEnc) {
        axios
          .get(auth_api_root + "/logout", {
            headers: {
              "x-api-key": auth_api_key,
              "websso-token": webSSOTokenEnc,
              "websso-iv": webSSOIVEnc,
            },
          })
          .then((response) => {
            setUser(null);
            setWebSSOIVEnc(null);
            setWebSSOTokenEnc(null);
            window.location.reload();
            setLoggingOut(false);
          })
          .catch((error) => {
            console.log(error);
            alert("Could not log out!");
          });
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        webSSOIVEnc,
        webSSOTokenEnc,
        loggingOut,
        actions: {
          logout: handleLogout,
        },
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
