import rules from "./RBACRules";
import Config from "../config/Config";

const { rbac_enabled } = Config;

const check = (rules, roles, action, data) => {
  var matchingPermissions;

  matchingPermissions = roles
    .filter((role) => role.ROLE_NAME in rules)
    .map((role) => rules[role.ROLE_NAME]);

  if (!matchingPermissions) {
    // role is not present in the rules
    return false;
  }

  let responses = matchingPermissions.map((permissions) => {
    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
      // static rule not provided for action
      return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];
      if (!permissionCondition) {
        // dynamic rule not provided for action
        return false;
      }

      return permissionCondition(data);
    }
    return false;
  });

  if (responses.includes(true)) return true;
  else return false;
};

const Can = (props) =>
  check(rules, props.roles, props.perform, props.data)
    ? props.yes()
    : rbac_enabled // only return "no" component when RBAC is enabled via config
    ? props.no()
    : props.yes();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
