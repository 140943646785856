import React from "react";
import { Container, Typography, Button, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppHeader from "./AppHeader";
import { Apps } from "./config/Apps";
import { Utils } from "./config/Utils";
import UserContextProvider from "./context/UserContext";
import DeveloperTools from "./DeveloperTools";

const useStyles = makeStyles((theme) => ({
  appLinks: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: theme.spacing(2),
    backgroundColor: "#fcfcfc",
  },
  appButton: {
    minWidth: "200px",
    margin: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    },
  },
  utils: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: theme.spacing(2),
    backgroundColor: "#fcfcfc",
  },
  utilButton: {
    margin: theme.spacing(2),
    display: "block",
    textAlign: "left",
    width: `calc(100% - ${theme.spacing(2) * 2}px)`,
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <Container>
      <UserContextProvider>
        <AppHeader />
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Application Links
          </Typography>
          <Box my={4} className={classes.appLinks}>
            {Apps.map((app) => (
              <Link
                href={app.link}
                target="_blank"
                key={app.id}
                underline="none"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.appButton}
                >
                  {app.dispName}
                </Button>
              </Link>
            ))}
          </Box>
        </Box>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Utilities
          </Typography>
          <Box my={4} className={classes.utils}>
            {Utils.map((util) => (
              <Link
                href={util.link}
                target="_blank"
                key={util.id}
                underline="none"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  className={classes.utilButton}
                >
                  {util.dispName}
                </Button>
              </Link>
            ))}
          </Box>
        </Box>
        <DeveloperTools />
      </UserContextProvider>
    </Container>
  );
}
