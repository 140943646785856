import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useUserContext } from "./context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    cursor: "pointer",
  },
  popOver: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  userName: {
    fontWeight: "bold",
  },
  rolesContainer: {
    margin: "20px 0",
    border: "1px solid #ddd",
    padding: "12px 0",
    borderRadius: "4px",
  },
}));

const getInitials = (name) => {
  // https://stackoverflow.com/questions/33076177/getting-name-initials-using-js

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

export default function AppHeader() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user, loggingOut } = useUserContext();
  const { logout } = useUserContext().actions;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          FSM Portal
        </Typography>
        {user ? (
          <>
            <Avatar
              className={classes.avatar}
              aria-describedby={id}
              onClick={handleClick}
            >
              {user.username && getInitials(user.username)}
            </Avatar>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              className={classes.popOver}
            >
              <div className={classes.popOver}>
                <Typography className={classes.userName}>
                  {user.username} ({user.netid})
                </Typography>
                <Typography variant="body2">{user.title}</Typography>
                <div className={classes.rolesContainer}>
                  <Typography variant="h6" className={classes.rolesTitles}>
                    Roles
                  </Typography>
                  <List>
                    {user.roles &&
                      user.roles.map((role) => (
                        <ListItem key={role.ROLE_ID}>
                          <ListItemText
                            primary={`${role.ROLE_NAME} (${role.ROLE_ID})`}
                          />
                        </ListItem>
                      ))}
                  </List>
                </div>
                <Button color="inherit" onClick={(e) => logout()}>
                  {loggingOut ? "Logging Out..." : "Logout"}
                </Button>
              </div>
            </Popover>
          </>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </Toolbar>
    </AppBar>
  );
}
