const Local = {
  visitor: {},
  Developer: {
    static: ["developer-pages:visit"],
  },
};

const Dev = {
  visitor: {},
  Developer: {
    static: ["developer-pages:visit"],
  },
};

const UAT = {
  visitor: {},
  Developer: {
    static: ["developer-pages:visit"],
  },
};

const Prod = {
  visitor: {},
  Developer: {
    static: ["developer-pages:visit"],
  },
};

let rules = null;

switch (process.env.REACT_APP_ENV.toLowerCase()) {
  case "local":
    rules = Local;
    break;
  case "dev":
    rules = Dev;
    break;
  case "uat":
    rules = UAT;
    break;
  case "prod":
    rules = Prod;
    break;
  default:
    alert("Invalid RBAC config: " + process.env.REACT_APP_ENV.toLowerCase());
    break;
}

let rulesExport = {
  ...rules,
};

export default rulesExport;
